import React, { useState } from "react";
import Header from "../Header";
import Home_footer from "../Home_footer";
import DashBoardTab from "./dashboard";


const AdminDashboard = () => {

    return (
        <div>
            <Header />
            <div style={{ minHeight: "500px" }}>
                <DashBoardTab />
            </div>
            <Home_footer />
        </div>
    )
}
export default AdminDashboard