import React, { useState } from "react";
import QuizAttempResult from "../quiz-attemp-result/quiz-attemp-result";


const DashBoardTab = () => {
    const [isShowActive, setIsShowActive] = useState(1)
    return (
        <section id="tabs">
            <div class="container">
                <h6 class="section-title h1">Dashboard</h6>
                <div class="row">
                    <div class="col-xs-12 ">
                        <nav>
                            <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                                <a class={`nav-item nav-link ${isShowActive == 1 && 'active'}`} onClick={() => setIsShowActive(1)} href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">Overview</a>
                                <a class={`nav-item nav-link ${isShowActive == 2 && 'active'}`} onClick={() => setIsShowActive(2)} href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">Quiz Result</a>
                                <a class={`nav-item nav-link ${isShowActive == 3 && 'active'}`} onClick={() => setIsShowActive(3)} href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false">Quiz Result User Wise</a>
                                <a class={`nav-item nav-link ${isShowActive == 4 && 'active'}`} onClick={() => setIsShowActive(4)} href="#nav-about" role="tab" aria-controls="nav-about" aria-selected="false">Report</a>
                            </div>
                        </nav>
                        <div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
                            <div class={`tab-pane fade ${isShowActive == 1 && 'show active'}`} id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                <QuizAttempResult />
                            </div>
                            <div class={`tab-pane fade ${isShowActive == 2 && 'show active'}`} id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                bbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbEt et consectetur ipsum labore excepteur est proident excepteur ad velit occaecat qui minim occaecat veniam. Fugiat veniam incididunt anim aliqua enim pariatur veniam sunt est aute sit dolor anim. Velit non irure adipisicing aliqua ullamco irure incididunt irure non esse consectetur nostrud minim non minim occaecat. Amet duis do nisi duis veniam non est eiusmod tempor incididunt tempor dolor ipsum in qui sit. Exercitation mollit sit culpa nisi culpa non adipisicing reprehenderit do dolore. Duis reprehenderit occaecat anim ullamco ad duis occaecat ex.
                            </div>
                            <div class={`tab-pane fade ${isShowActive == 3 && 'show active'}`} id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                                cccccccccccccccccccccccccccccccccccEt et consectetur ipsum labore excepteur est proident excepteur ad velit occaecat qui minim occaecat veniam. Fugiat veniam incididunt anim aliqua enim pariatur veniam sunt est aute sit dolor anim. Velit non irure adipisicing aliqua ullamco irure incididunt irure non esse consectetur nostrud minim non minim occaecat. Amet duis do nisi duis veniam non est eiusmod tempor incididunt tempor dolor ipsum in qui sit. Exercitation mollit sit culpa nisi culpa non adipisicing reprehenderit do dolore. Duis reprehenderit occaecat anim ullamco ad duis occaecat ex.
                            </div>
                            <div class={`tab-pane fade ${isShowActive == 4 && 'show active'}`} id="nav-about" role="tabpanel" aria-labelledby="nav-about-tab">
                                dddddddddddddddddddddddddddddddddddddEt et consectetur ipsum labore excepteur est proident excepteur ad velit occaecat qui minim occaecat veniam. Fugiat veniam incididunt anim aliqua enim pariatur veniam sunt est aute sit dolor anim. Velit non irure adipisicing aliqua ullamco irure incididunt irure non esse consectetur nostrud minim non minim occaecat. Amet duis do nisi duis veniam non est eiusmod tempor incididunt tempor dolor ipsum in qui sit. Exercitation mollit sit culpa nisi culpa non adipisicing reprehenderit do dolore. Duis reprehenderit occaecat anim ullamco ad duis occaecat ex.
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}
export default DashBoardTab