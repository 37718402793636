import React from 'react'
import { BsFacebook } from "react-icons/bs";
import {BsInstagram} from "react-icons/bs";
import {BsYoutube} from "react-icons/bs";
import {BsLinkedin} from "react-icons/bs";
import { BsWhatsapp } from "react-icons/bs";
import { BsTwitter } from "react-icons/bs";
import { MdEmail } from "react-icons/md";

const Home_footer = () => {
  return (
    <>
      <div className="container-fluid bg-dark">
        <div className="row">
            <div className="col-md-4 text-center footer_links mt-5">
                <h5 className='text-info'>About</h5>
                <p className='text-white'> www.quizapp.com is open source study platform to provide free of cost knowledge to student, the best online study source platform to gain knowledge. Here We are provide best general knowledge and different types of general knowledge to improve quality of knowledge</p>
            </div>
            <div className="col-md-4 text-center mt-5">
            <h5 className='text-info text-center'>Social Links</h5>
            <a href='https://www.facebook.com/home.php'><BsFacebook className='footer_icons'/></a>
            <a href='https://www.instagram.com/'><BsInstagram className='footer_icons'/></a>
           <a href="https://www.youtube.com/@Onlinenursingknowledge"> <BsYoutube className='footer_icons'/></a>
           <a href='https://www.linkedin.com/feed/'> <BsLinkedin className='footer_icons'/></a>
           <a href='https://web.whatsapp.com/'> <BsWhatsapp className='footer_icons'/></a>
            <BsTwitter className='footer_icons'/>
            <a href='https://mail.google.com/mail/u/0/#inbox'><MdEmail className='footer_icons'/></a>

            </div>
            <div className="col-md-4 text-center text-white mt-5">
            <h5 className='text-info'>Contact us</h5>
             <h6>quizapp@gmail.com</h6>
             <h6>+91 7737955607</h6>
            </div>
        </div>
      </div>
    </>
  )
}

export default Home_footer
