import React, { useState, useRef  } from "react";

const Chatbot = () => {
  const buttonRef = useRef();
  const [userInput, setUserInput] = useState([]);
  const [message, setMessage] = useState([]);
  const [show, setShow] = useState(false);

  let apiKey = "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";

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      buttonRef.current.click();
    }
  };
  
  const handleClick = async (e) => {
    if(!userInput){
      return
    }
    const value = {
      role: "user",
      content: userInput,
    };
    setMessage((lastState) => {
      return [...lastState, value];
    });
    console.log(message);
    setUserInput("");

    const API_URL = "https://api.openai.com/v1/chat/completions";

    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${apiKey}`,
      },
      body: JSON.stringify({
        model: "gpt-3.5-turbo",
        messages: [{ role: "user", content: userInput }],
        temperature: 0.7,
      }),
    };
    await fetch(API_URL, requestOptions)
      .then((res) =>
        res.json().then((data) => {
          console.log(data.choices[0].message.content);
          const result = data.choices[0].message;
          setMessage((lastState) => {
            return [...lastState, result];
          });
          return result;
        })
      )
      .catch((err) => {
        console.log(err);
      });
  };




  return (
    <>
      <div className="show-chatbot">
        <button className="chatbot-toggler">
          {show ? (
            <span class="material-icons" onClick={() => setShow(false)}>
              close
            </span>
          ) : (
            <span class="material-icons" onClick={() => setShow(true)}>
              mode_comment
            </span>
          )}
        </button>
        <div className="chatbot" style={show ? {} : { display: "none" }}>
          <header>
            <h2>chatbot</h2>
            <span class="material-icons"onClick={() => setShow(false)} >close</span>
          </header>
          <div className="main_area">
            <ul className="chatbox">
              <li className="chat incoming">
                <span className="material-icons">face</span>
                <p>
                Hello! I'm ChatBot!!<br/>How can I help you today?
                </p>
              </li>
              <div>
                {message.map((val) => (
                  <div>
                    <li className="chat outgoing">
                      {val.role == "user" && <span>{val.content}</span>}
                    </li>

                    {val.role == "assistant" && (
                      <li className="chat incoming">
                          <span className="material-icons">face</span>
                        <p>
                          {val.content}
                        </p>
                      </li>
                    )}
                  </div>
                ))}
              </div>
            </ul>
          </div>
          <div className="chat_input">
            <input
            onKeyPress={handleKeyPress}
              type="text"
              placeholder="Enter a message..."
              required
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
            ></input>
            <span
              id="send-btn"
              className="material-icons"
              onClick={(e)=>handleClick(e)}
              ref={buttonRef}
            >
              send
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chatbot;
