import React, { useEffect, useState } from "react";
import { config } from "../../../utils/apiUrl";
import API from "../../../utils/apiCalling";
// import { PieChart } from 'react-minimal-pie-chart';
import PieChart from 'react-pie-graph-chart';
const QuizAttempResult = () => {
    const api = new API()
    const [allQuizResult, setAllQuizResult] = useState([])
    useEffect(() => {
        getQuizAttempResult()
    }, [])
    const getQuizAttempResult = async () => {
        const result = await api.post(config.getAttempQuiz, {})
        if (result && result.code == 200) {
            setAllQuizResult(result?.data)
        }
    }
    return (
        <div className="quiz_attemp_result_admin">
            {allQuizResult?.map((quiz) => (
                <div className="card mt-5">
                    <div className="card-header">
                        <h4>{quiz.name}</h4>
                        <h6>Mobile. {quiz.mobile}</h6>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-4">
                                Quiz Name : {quiz.quiz_name}
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Total Question</th>
                                            <th scope="col">Correct Question</th>
                                            <th scope="col">Percentage</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{quiz.total_que}</td>
                                            <td>{quiz.correct_que}</td>
                                            <td>{(quiz.correct_que * 100) / quiz.total_que}</td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                            <div className="col-md-4 text-center overall_result_section">
                                <h4>Overall Result</h4>
                                <h4>{(quiz.correct_que * 100) / quiz.total_que > 33 ? <span style={{ color: "blue" }}>Pass</span> : <span style={{ color: "red" }}>Fail</span>}</h4>

                            </div>
                            <div className="col-md-4 overall_result_section">
                                <PieChart data={[
                                    {
                                        title: "Total Question", type: "Total Question", value: parseInt(quiz.total_que), color: "blue"
                                    },
                                    {
                                        title: "Correct Question", type: "Correct Question", value: parseInt(quiz.correct_que), color: "orange"
                                    },
                                    {
                                        title: "Wrong Question", type: "Wrong Question", value: parseInt(quiz.total_que) - parseInt(quiz.correct_que), color: "red"
                                    }
                                ]} />
                                {/* 
                                <PieChart
                                    data={[

                                        {
                                            title: "Total Question", type: "Total Question", value: parseInt(quiz.total_que), color: "blue"
                                        },
                                        {
                                            title: "Correct Question", type: "Correct Question", value: parseInt(quiz.correct_que), color: "red"
                                        }
                                    ]}
                                />; */}
                            </div>
                        </div>
                    </div>
                </div>
            ))}

        </div>
    )
}
export default QuizAttempResult