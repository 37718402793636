import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import AdminDashboard from './Components/admin-dashboard/admin-dashboard'
import Home from './Components/Home'
import HomePage from './Components/home_page'
import AdminLogin from './Components/login/login'
import Polices from './Components/Polices'
import QuizList from './Components/quizList'
import Result from './Components/Result'
import StartQuiz from './Components/startQuiz'

const App = () => {
  return (
    <>
       
      <Router>
          <Routes>
            {/* <Route path='/Homepageheader' element={<Home_page_header/>}/> */}
            <Route path='/' element={<HomePage/>}/>
            <Route path='/Polices' element={<Polices/>}/>
            <Route path='/quiz-list' element={<QuizList/>}/>
            <Route path='/start-quiz/:type' element={<StartQuiz/>}/>
            <Route path='/Result' element={<Result/>}/>
            <Route path='/admin-login' element={<AdminLogin/>}/>
            <Route path='/admin-dashboard' element={<AdminDashboard/>}/>
          </Routes>
        </Router> 
      
    </>
  )
}

export default App
