import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { static_question } from "./data";
import { useNavigate } from 'react-router-dom';
import Header from "./Header";
import Webcam from "react-webcam";
import Swal from 'sweetalert2'
import { useParams } from 'react-router-dom'
import { config } from "../utils/apiUrl";
import API from "../utils/apiCalling";

const StartQuiz = () => {
  const api = new API()
  const { type } = useParams();
  const navigate = useNavigate();

  const Ref = useRef(null);

  const [timer, setTimer] = useState();
  const [count, setCount] = useState(0);
  const [selectedOption, setSelectedOption] = useState("")
  const [question, setQuestion] = useState([])
  const [isSetFinalPage, setIsSetFinalPage] = useState(false)

  useEffect(() => {
    console.log("type issssssssssssssssssssssss", type)
    let questionSaved = localStorage.getItem("questions")
    if (questionSaved) {
      setQuestion(questionSaved)
    } else {
      setQuestion([])
    }
  }, [])
  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };
  useEffect(() => {
    getQuestion()
  }, [])
  const getQuestion = async () => {
    setQuestion([])
    fetch(`http://localhost:5000/dashboard/getQuestion/?type=${type}`).then(response => {
      return response.json()
    })
      .then(data => {
        setQuestion(data.data)
      }).catch(error => {
        console.log(error)
        console.log("____________________________________________", static_question)
        let newQuestion = static_question
        setQuestion(newQuestion)
      })

    // console.log("question from api", result)
    // result = await result.json()
    // if (result && result.data && result.data.length > 0){
    //   setQuestion(result.data)
    // } else {
    //   console.log("elseeeeeeeeeeeeeeeeeeeeeeeeeee", static_question)
    //   setQuestion(static_question)
    // }
  }

  const startTimer = (e) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (hours > 9 ? hours : "0" + hours) +
        ":" +
        (minutes > 9 ? minutes : "0" + minutes) +
        ":" +
        (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const clearTimer = (e) => {
    setTimer("00:20:00");

    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 1200);
    return deadline;
  };

  useEffect(() => {
    clearTimer(getDeadTime());
  }, []);

  const onClickReset = () => {
    clearTimer(getDeadTime());
  };

  const clickNext = () => {
    if (count < question.length - 1) {
      setCount(count + 1);
      saveQuestion()
      setSelectedOption("")
    } else {
      setIsSetFinalPage(true)
      // navigate("/Result")
    }
  };

  const remarkQue = (e) => {
    question[count].remark = true
  }

  function clickPre() {
    if (count > 0) {
      setCount(count - 1);
      saveQuestion()
      setSelectedOption("")
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: "You are already at first question",
        showCloseButton: false,
        showConfirmButton: false,
        timer: 5000
      });
    }
  }
  const selectedOptionFun = (option) => {
    setSelectedOption(option)
    question[count].selectedAnswer = option

  }

  const saveQuestion = () => {
    question[count].visited = true
    console.log("selected question is", question)
    localStorage.setItem("questions", JSON.stringify(question))
  }

  const btnClick = (val) => {
    setCount(val)
  }
  const saveAttempQuiz = () => {
    let user = localStorage.getItem('user')
    let { name, mobile } = JSON.parse(user)
    let data = {
      name: name,
      mobile: mobile,
      quiz_name : type,
      status: 1,
      correct_que: question.filter(que => que.answer == que.selectedAnswer).length,
      total_que: question.length,
      questions: JSON.stringify(question)
    }
    const result = api.post(config.saveAttempQuiz, data)
    if (result && result.code == 200) {
      navigate("/Result")
    } else {
      navigate("/Result")
    }
  }

  return (
    <>
      <Header />
      {!isSetFinalPage ?
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 text-center">
              <h3 className="mt-2">{type} Quiz</h3>
            </div>
          </div>

          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-6 quiz_box">
              <h4 className="text-white">Question {count + 1} of {question.length}</h4>
              {/* <hr className="hr_ques" /> */}
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-3 quiz_box">
              <h4 className="timer_text text-white">{timer}</h4>
            </div>
            <div className="col-md-1"></div>
          </div>

          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-6">
              <div className="col-md-1"></div>

              <h5 className="mt-3"> {question && question.length > 0 && question[count].question}</h5>
              <div className="options">
                <form action="">
                  {question && question.length > 0 &&
                    question[count].option.map((opt) => (
                      <>
                        <input checked={opt == selectedOption || opt == question[count]?.selectedAnswer} type="radio" id={opt} value="a" name="quiz" onClick={() => selectedOptionFun(opt)} /> <label for={opt}>{opt}</label>
                        <br />
                      </>
                    ))}
                </form><br /><br /><br />
                <button className="previous_btn" onClick={clickPre}>Previous</button>
                <button className="remark_btn" onClick={remarkQue}>Review</button>
                <button className="next_btn" onClick={clickNext}>Save & Next</button>
                <button className="submit_btn" onClick={()=>setIsSetFinalPage(true)}>Submit</button>
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-3 quiz_box">
              <Webcam className="webcam" />
              <h4 className="suggetion">
                {" "}
                Attempt Question <button className="attempt"></button>
              </h4>

              <h4 className="suggetion">
                {" "}
                Marked Question <button className="marked"></button>
              </h4>
              <h4 className="suggetion">
                {" "}
                Not Attempt <button className="not_attempt"></button>
              </h4>

              {Array.apply(null, { length: question.length }).map((a, index) => (
                <>
                  <button style={(question[index]?.selectedAnswer) ? { backgroundColor: "green" } : question[index]?.remark ? { backgroundColor: "yellow" } : (!question[index]?.selectedAnswer && question[index]?.visited == true) ? { backgroundColor: "red" } : {}} className={count == index ? "number_btn_active" : "number_btn"} onClick={() => btnClick(index)}>{index + 1}</button>
                </>
              ))}
              <br />
            </div>
            <div className="col-md-1"></div>
          </div>

        </div>
        :
        <div className="container-fluid">
          <div className="quiz-final-section text-center">
            <h2>Click on submit to quiz final submittion</h2>
            <button className="btn btn-primary mt-5" onClick={saveAttempQuiz}>Submit Quiz</button>
          </div>
        </div>
      }
    </>
  );
};

export default StartQuiz;
