import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Header from './Header'
import { useNavigate } from 'react-router-dom';


const Result = () => {
  const navigate = useNavigate();
  const [allQuestion, setAllQuestion] = useState([])
  useEffect(() => {
    let question = localStorage.getItem('questions')
    if (question) setAllQuestion(JSON.parse(question))
  }, [])

  const attempQue = () => {
    let attempQue = allQuestion.filter(que => que.selectedAnswer)
    return attempQue.length
  }
  const correctQue = () => {
    let correctQue = allQuestion.filter(que => que.answer == que.selectedAnswer)
    return correctQue.length
  }

  const logout = () => {
    localStorage.clear()
    navigate("/")
    window.location.reload()
  }
  return (
    <div>
      <Header />
      <div style={{ backgroundColor: "gray" }}>
        <h3 align="center" className='mb-5'>Result</h3>
        <div className="row">
          <div className="col-md-4 text-center">
            <div className='score'>
              <div className='score-body'>
                <h1>{Math.round((100 * correctQue()) / allQuestion.length)}%</h1>
                <h6>{Math.round((100 * correctQue()) / allQuestion.length) < 33 ? 
                <>
                <span style={{color:"red"}}>Fail</span>
                <h6>Better luck next time</h6> 
                </>
                :
                <>
                Pass
                <h6>Great !</h6>
                </>
                }
                </h6>
              </div>
            </div>
            <div className='score-summary'>
              Total Question : {allQuestion.length}<br />
              Attempt Question : {attempQue()}/{allQuestion.length}<br />
              Correct Question : {correctQue()}/{allQuestion.length}<br />
            </div>
          </div>
          <div className="col-md-8">
            <div className='question_result'>
              <h2 align="center">question result</h2>
              {allQuestion?.map((que, index) => (

                <div className='card review-que-section'>
                  <div className='card-header'>
                    {index + 1}. {que.question}
                  </div>
                  <div className='card-body'>
                    <div className='row'>
                      {que.option.map((opt, indexx) => (
                        <div className='col-md-6'>
                          {indexx + 1}. <span className={opt == que.answer ? "correctAnswerMark" : ""} style={que.selectedAnswer == opt ? { color: "red" } : {}}>{opt}</span>    {que.selectedAnswer == opt ? <small className='ansTag'> Selected</small> : opt == que.answer ? <small className='ansTag'>Correct </small> : ""}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>

          </div>

          <div className="col-sm-12 mt-3 mb-5 text-center">
            <button onClick={logout} className='finish_test'>logout</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Result
