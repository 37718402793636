export let static_question = [
    {
        question: "Which of the following access modifiers in Java are used to restrict access to a method or field to within the same package?",
        option : ["public", "protected", "private", "package-private"],
        answer : "package-private"
    },
    {
        question: "What is the Java keyword used to define a class that cannot be instantiated?",
        option : ["abstract", "final", "static", "none of the above"],
        answer : "abstract"
    },
    {
        question: "What is the Java keyword used to create an instance of a class?",
        option : ["new", "create", "instantiate", "make"],
        answer : "new"
    },
    {
        question: "Which of the following is not a primitive data type in Java?",
        option : ["int", "boolean", "string", "char"],
        answer : "string"
    },
    {
        question: "What is the result of the expression 5 / 2 in Java?",
        option : ["2.5", "2", "3", "None of the above"],
        answer : "2"
    },
    {
        question: "Which of the following is a loop construct in Java that executes the loop body at least once?",
        option : ["for loop", "while loop", "do-while loop", "switch statement"],
        answer : "do-while loop"
    },
    {
        question: "Which of the following is not a valid way to declare and initialize an array in Java?",
        option : ["option1 -4", "option2-4", "option3-4", "option4-4"],
        answer : "option2-4"
    },
    {
        question: "question four ",
        option : ["option1 -4", "option2-4", "option3-4", "option4-4"],
        answer : "option1 -4"
    },{
        question: "question four ",
        option : ["option1 -4", "option2-4", "option3-4", "option4-4"],
        answer : "option4-4"
    },{
        question: "question four ",
        option : ["option1 -4", "option2-4", "option3-4", "option4-4"],
        answer : "option4-4"
    },
    {
        question: "question four ",
        option : ["option1 -4", "option2-4", "option3-4", "option4-4"],
        answer : "option1 -4"
    },{
        question: "question four ",
        option : ["option1 -4", "option2-4", "option3-4", "option4-4"],
        answer : "option3-4"
    },{
        question: "question four ",
        option : ["option1 -4", "option2-4", "option3-4", "option4-4"],
        answer : "option1 -4"
    },{
        question: "question four ",
        option : ["option1 -4", "option2-4", "option3-4", "option4-4"],
        answer : "option3-4"
    },{
        question: "question four ",
        option : ["option1 -4", "option2-4", "option3-4", "option4-4"],
        answer : "option2-4"
    },
]