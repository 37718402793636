import React from 'react'
import '../style.css';

const Header = () => {
  return (
    <>
    <div className="container-fluid">
    <div className="row">
    <div className="col-md-12 text-center WebHead">
       <img src='/quiz_logo.png' className='img_main'/>
    </div>
    </div>
    </div>
    </>
  )
}

export default Header
